import React, { Component } from 'react';
import logo from './images/ROMANYGIRLwebsite.jpg';
import './App.css';
//import { withAuthenticator } from 'aws-amplify-react'
import Amplify, { Auth } from 'aws-amplify';
import aws_exports from './aws-exports';
Amplify.configure(aws_exports);

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
        <a href="https://www.amazon.com/Romany-Girl-Story-English-Gypsy/dp/B095LGXJXX/ref=sr_1_12?dchild=1&keywords=romany+girl+book&qid=1633918640&sr=8-12">
          <img src={logo} className="App-logo" alt="logo" />
         </a>
        </header>
      </div>
    );
  }
}

export default App;
